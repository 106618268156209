var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"95782660984b04f15109c364948fab0a588446e8"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

/*
 * IMPORTS
 */
import * as Sentry from '@sentry/nextjs' // NPM: Sentry.io client.


/*
 * GLOBALS
 */
const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN


/*
 * OBJECTS
 */
Sentry.init({
  'dsn': SENTRY_DSN,
  'tracesSampleRate': 1.0
})
